import { create } from 'zustand'
import { produce } from 'immer'
import { devtools } from 'zustand/middleware'

const store = (set) => ({
  currentProject: null,
  setCurrentProject: (data) => set(
    produce((st) => {
      st.currentProject = data
    }),
    false,
    'CONFIGURATOR/SET_PROJECT',
  ),
  resetProject: () => set(
    produce((st) => {
      st.currentProject = null
    }),
    false,
    'CONFIGURATOR/RESET_PROJECT',
  ),
})

const useConfiguratorStore = create(devtools(store, { name: 'Configurator Store' }))

export default useConfiguratorStore
